import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import loc from 'Components/languages';
import Pagination from 'Components/pagination';
import { getCustomerAnlys } from 'Redux/actions';
import PurposeListItem from './PurposeListItem.jsx';
import styles from './Modal.less';

class ModalPurpose extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '485px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen && !prevIsOpen) {
			this.setState({
				page: 1,
			}, this.fetchData);
		}
	}

	fetchData() {
		const { page } = this.state;
		let param = {
			payload: {
				page,
			},
		};
		this.props.getCustomerAnlys(param);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	render() {
		const { isOpen, onToggle, onSelect, customerAnlysInfo } = this.props;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.purpose }
				style={ this.modalStyle }
			>
				<div className={ styles.desc }>
					{ loc.changePurposePopupDesc }
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							customerAnlysInfo.isFetching ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								customerAnlysInfo.data && customerAnlysInfo.data.data && customerAnlysInfo.data.data.map((purpose, index) => (
									<PurposeListItem
										key={ `modal-purpose-info-${index}` }
										purpose={ purpose }
										onSelect={ onSelect }
									/>
								))
							)
						}
					</ul>
				</div>
				<Pagination
					data={ customerAnlysInfo.data }
					onPageChange={ this.onChangePageHandler }
				/>
			</Modal>
		);
	}
}

ModalPurpose.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		customerAnlysInfo: state.customerAnlysInfo,
	}),
	(dispatch) => ({
		getCustomerAnlys: para => dispatch(getCustomerAnlys(para)),
	})
)(ModalPurpose);