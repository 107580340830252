import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './PurposeListItem.less';

class PurposeListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { purpose, onSelect } = this.props;
		onSelect(purpose);
	}

	render() {
		const { purpose } = this.props;
		return (
			<li className={ styles.container }>
				<div className={ styles.details } onClick={ this.onClickHandler }>
					<div className={ styles.title }>{ purpose.description }</div>
				</div>
			</li>
		);
	}
}

PurposeListItem.propTypes = {
	purpose: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default PurposeListItem;