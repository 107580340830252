exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1KyX4 {\n  position: static;\n  position: initial;\n}\n._3JxSQ {\n  height: calc(100% - 80px);\n}\n._1pCUs {\n  position: absolute;\n  top: 0;\n  padding: 13px 15px;\n  color: #BD9471;\n  cursor: pointer;\n}\n._1pCUs.AM436 {\n  left: 0;\n}\n._1pCUs._1vvr8 {\n  right: 0;\n}\n._2qC7f {\n  margin-top: 15px;\n  margin-bottom: 15px;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n.JgPv_ {\n  padding-left: 20px;\n  padding-right: 20px;\n  line-height: 26px;\n  font-size: 15px;\n  color: #A0A0A0;\n  background-color: #EFF1F2;\n}\n.AXZgf {\n  height: calc(100% - 36px);\n  overflow: auto;\n}\n.XHoH4 {\n  height: 100%;\n}\n.-pr1a {\n  min-height: calc(100% - 50px);\n  background-color: #F7F7F7;\n}\n.-pr1a ._38kjR {\n  font-weight: bold;\n  background-color: #F6F4F0;\n  color: #A0A0A0;\n  text-align: center;\n  font-size: 20px;\n  font-family: \"Cormorant Garamond\", \"Noto Serif\", \"Georgia\", \"Times\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\";\n  padding-top: 8px;\n}\n.-pr1a ._2DIdt {\n  background-color: #F6F4F0;\n  color: #A0A0A0;\n  text-align: center;\n  font-size: 14px;\n  padding-bottom: 8px;\n}\n.-pr1a .kmzlQ ._2uQ2Y {\n  font-weight: 600;\n  font-size: 16px;\n  padding: 5px 20px;\n}\n.-pr1a .kmzlQ ._1yEGW {\n  margin-bottom: 40px;\n}\n._2xTSZ ul {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"loadingOverlay": "_1KyX4",
	"loadingOverlayPromotions": "_3JxSQ",
	"button": "_1pCUs",
	"left": "AM436",
	"right": "_1vvr8",
	"tab": "_2qC7f",
	"title": "JgPv_",
	"main": "AXZgf",
	"mainFull": "XHoH4",
	"promotionDetails": "-pr1a",
	"promotionName": "_38kjR",
	"promotionDescription": "_2DIdt",
	"criteria": "kmzlQ",
	"subtitle": "_2uQ2Y",
	"subsection": "_1yEGW",
	"list": "_2xTSZ"
};