exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1_Y4u {\n  font-weight: bold;\n  color: #99785C;\n  font-size: 18px;\n}\n._2MVbJ {\n  color: #A0A0A0;\n}\n._25eC- {\n  color: #333333;\n}\n._2ar4T {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: 'row';\n          flex-direction: 'row';\n  color: #333333;\n}\n._2ar4T ._2-BgK {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n._2ar4T .CsIe4 {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"title": "_1_Y4u",
	"label": "_2MVbJ",
	"name": "_25eC-",
	"details": "_2ar4T",
	"phone": "_2-BgK",
	"tier": "CsIe4"
};