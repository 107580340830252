exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1M9sh {\n  cursor: pointer;\n  border-bottom: 1px solid #E5E5E5;\n}\n._1M9sh .cxFRU {\n  margin-left: 20px;\n  margin-right: 20px;\n  padding: 10px 0 10px;\n  line-height: 1;\n}\n._1M9sh .cxFRU ._2lfj- {\n  color: #BD9471;\n  font-size: 18px;\n  padding-top: 6px;\n  padding-bottom: 8px;\n}\n._1M9sh .cxFRU .WF-KH {\n  display: inline-block;\n  vertical-align: top;\n  width: calc(100% - 80px);\n  color: #A0A0A0;\n  font-size: 12px;\n}\n._1M9sh .cxFRU ._3fTPL {\n  display: inline-block;\n  vertical-align: top;\n  width: 80px;\n  color: #A0A0A0;\n  font-size: 12px;\n  text-align: right;\n}\n._1M9sh.vht9H {\n  background-color: #BD9471;\n}\n._1M9sh.vht9H .cxFRU ._2lfj-,\n._1M9sh.vht9H .cxFRU .WF-KH,\n._1M9sh.vht9H .cxFRU ._3fTPL {\n  color: #fff;\n}\n", ""]);

// Exports
exports.locals = {
	"serviceItem": "_1M9sh",
	"details": "cxFRU",
	"title": "_2lfj-",
	"desc": "WF-KH",
	"code": "_3fTPL",
	"isSelected": "vht9H"
};