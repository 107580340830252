import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import classnames from 'classnames';
import { convertFloatToPrice } from 'Helpers/helpers';
import { setItemToCurrentShop, unsetItemFromCurrentShop } from 'Redux/actions';
import loc from 'Components/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import DropdownMenu from 'Components/dropdown-menu';
import ModalLotNo from './ModalLotNo.jsx';
import styles from './TransactionItemRow.less';
import ModalQuantityDiscount from './ModalQuantityDiscount.jsx';

class TransactionItemRow extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
			showModalLotNo: false,
			showModalQuantityDiscount: false,
		};
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onToggleModalLotNoHandler = this.onToggleModalLotNoHandler.bind(this);
		this.onToggleModalQuantityDiscountHandler = this.onToggleModalQuantityDiscountHandler.bind(this);
		this.onClickInsertLotNoHandler = this.onClickInsertLotNoHandler.bind(this);
		this.onClickRemoveHandler = this.onClickRemoveHandler.bind(this);
		this.onClickAddToFavoriteHandler = this.onClickAddToFavoriteHandler.bind(this);
		this.onClickRemoveFromFavoriteHandler = this.onClickRemoveFromFavoriteHandler.bind(this);
		this.onChangeQuantityDiscountHandler = this.onChangeQuantityDiscountHandler.bind(this);
	}

	onSelectLotNoHandler(lotNo) {
		const { index, onSelectLotNo } = this.props;
		this.setState({
			showModalLotNo: false,
		});
		onSelectLotNo(index, lotNo);
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		const { onShowOverlay, onHideOverlay } = this.props;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
		if (showDropdownMenu) {
			onHideOverlay();
		} else {
			onShowOverlay(this.onToggleDropdownMenuHandler);
		}
	}

	onToggleModalLotNoHandler() {
		const { showModalLotNo } = this.state;
		this.setState({
			showModalLotNo: !showModalLotNo,
		});
	}

	onToggleModalQuantityDiscountHandler() {
		const { showModalQuantityDiscount } = this.state;
		this.setState({
			showModalQuantityDiscount: !showModalQuantityDiscount,
		});
	}

	onClickInsertLotNoHandler(e) {
		e.preventDefault();
		this.setState({
			showModalLotNo: true,
		}, this.props.onHideOverlay);
	}

	onClickRemoveHandler(e) {
		const { index, onRemove } = this.props;
		e.preventDefault();
		onRemove(index);
	}

	onClickAddToFavoriteHandler(e) {
		e.preventDefault();
		const { transactionItem, setItemToCurrentShop, onHideOverlay } = this.props;
		setItemToCurrentShop({
			payload: {
				item_code: transactionItem.get('item_code'),
			},
		});
		onHideOverlay();
	}

	onClickRemoveFromFavoriteHandler(e) {
		e.preventDefault();
		const { transactionItem, unsetItemFromCurrentShop, onHideOverlay } = this.props;
		unsetItemFromCurrentShop({
			payload: {
				item_code: transactionItem.get('item_code'),
			},
		});
		onHideOverlay();
	}

	onChangeQuantityDiscountHandler(data) {
		const { index, onChange, onHideOverlay } = this.props;
		onChange(index, data);
		onHideOverlay();
	}

	render() {
		const { index, transactionItem, exceptions, type, isReturn, appLanguage } = this.props;
		const { showDropdownMenu, showModalQuantityDiscount, showModalLotNo } = this.state;
		const brand = transactionItem.get('brand') || {};
		return (
			<Fragment>
				<div className={ classnames('uk-card uk-card-default uk-card-body uk-card-small uk-box-shadow-medium uk-margin-small-left', styles.card, transactionItem.get('print_disable') && styles.printDisable, exceptions && exceptions.length > 0 && styles.invalid) }>
					<div className="uk-flex">
						<div className="uk-width-1-1">
							{
								appLanguage.language === 'en' ? (
									transactionItem.get('item_name_en')
								) : (
									transactionItem.get('item_name_tc')
								)
							}
							{
								isReturn && transactionItem.get('item_quantity') < 0 && (
									<span className={ classnames('uk-badge', styles.badgeReturn) }>
										{ loc.return }
									</span>
								)
							}
						</div>
					</div>
					<div className="uk-flex">
						<div className="uk-width-2-6">
							<div className="uk-flex uk-child-width-1-3">
								<span>{ transactionItem.get('item_code') }</span>
								<span>{ transactionItem.get('lot_no') }</span>
								<span>{ transactionItem.get('actlot_no') }</span>
							</div>
						</div>
						<div className="uk-width-1-6">
							<div>
								<span>
									{
										appLanguage.language === 'en' ? (
											brand.name_en
										) : (
											brand.name_tc
										)
									}
								</span>
							</div>
						</div>
						<div className="uk-width-3-6 uk-text-right uk-margin-medium-right">
							<div className="uk-flex uk-child-width-1-4">
								<div>
									{ convertFloatToPrice(transactionItem.get('price_original')) }
								</div>
								{/* <div>
									{ convertFloatToPrice(transactionItem.get('price_sales')) }
								</div> */}
								<div className={ styles.link } onClick={ this.onToggleModalQuantityDiscountHandler }>
									{ convertFloatToPrice(transactionItem.get('unit_price')) }
								</div>
								<div className={ styles.link } onClick={ this.onToggleModalQuantityDiscountHandler }>
									{ transactionItem.get('item_quantity') }
								</div>
								<div>
									<div className={ styles.link } onClick={ this.onToggleModalQuantityDiscountHandler }>
										{ convertFloatToPrice(transactionItem.get('retail_amount')) }
									</div>
									{
										(transactionItem.get('final_amount') - transactionItem.get('retail_amount') != 0) && (
											<div className="uk-text-success">
												{ convertFloatToPrice(transactionItem.get('final_amount') - transactionItem.get('retail_amount')) }
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
					{
						exceptions && exceptions.map((exception, j) => (
							<div
								key={ `transaction-item-row-${index}-exception-${j}` }
								className="uk-width-1-1 uk-margin-small-top uk-text-meta uk-text-danger uk-text-bold"
							>
								<FontAwesomeIcon icon={ faExclamationCircle } />&nbsp;
								{ loc[exception.errorCode] }
							</div>
						))
					}
					<div className="uk-padding-small uk-padding-remove-vertical uk-position-top-right uk-position-small">
						<div className={ styles.button } onClick={ this.onToggleDropdownMenuHandler }>
							<FontAwesomeIcon icon={ faEllipsisV } />
						</div>
						<DropdownMenu width="240px" active={ showDropdownMenu } onToggle={ this.onToggleDropdownMenuHandler }>
							{
								type !== 'shopOrder' && (
									<li><a href="#" onClick={ this.onClickInsertLotNoHandler }>{ loc.insertLotNo }</a></li>
								)
							}
							{
								transactionItem.get('is_favorite') ? (
									<li><a href="#" onClick={ this.onClickRemoveFromFavoriteHandler }>{ loc.removeFromFavorite }</a></li>
								) : (
									<li><a href="#" onClick={ this.onClickAddToFavoriteHandler }>{ loc.addToFavorite }</a></li>
								)
							}
							<li><a href="#" className="uk-text-danger" onClick={ this.onClickRemoveHandler }>{ loc.removeItem }</a></li>
						</DropdownMenu>
					</div>
				</div>

				<ModalLotNo
					isOpen={ showModalLotNo }
					onToggle={ this.onToggleModalLotNoHandler }
					onSelect={ this.onSelectLotNoHandler }
					itemCode={ transactionItem.get('item_code') }
				/>

				<ModalQuantityDiscount
					isOpen={ showModalQuantityDiscount }
					type={ type }
					onToggle={ this.onToggleModalQuantityDiscountHandler }
					onChange={ this.onChangeQuantityDiscountHandler }
					transactionItem={ transactionItem }
				/>
			</Fragment>
		);
	}
}

TransactionItemRow.propTypes = {
	index: PropTypes.number.isRequired,
	transactionItem: PropTypes.instanceOf(Map).isRequired,
	type: PropTypes.string.isRequired,
	exceptions: PropTypes.array,
	isReturn: PropTypes.bool.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
	onSelectLotNo: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
		setItemToCurrentShopInfo: state.setItemToCurrentShopInfo,
		unsetItemFromCurrentShopInfo: state.unsetItemFromCurrentShopInfo,
	}),
	(dispatch) => ({
		setItemToCurrentShop: para => dispatch(setItemToCurrentShop(para)),
		unsetItemFromCurrentShop: para => dispatch(unsetItemFromCurrentShop(para)),
	})
)(TransactionItemRow);