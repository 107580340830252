exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".C8znr {\n  padding-bottom: 10px;\n}\n.Pevxi {\n  padding-right: 10px;\n}\n.Pevxi ul.uk-tab {\n  margin-left: 0 !important;\n}\n.Pevxi ul.uk-tab li > a {\n  border: 1px solid #BD9471;\n  color: #BD9471;\n}\n.Pevxi ul.uk-tab li > a:hover {\n  color: #BD9471;\n}\n.Pevxi ul.uk-tab > li.uk-active > a {\n  background-color: #BD9471;\n  border: 1px solid #BD9471;\n  color: #fff;\n}\n.Pevxi ul.uk-tab > li.uk-active > a:hover {\n  color: #fff;\n}\n.Pevxi ul.uk-tab li:first-of-type > a {\n  border-radius: 5px 0 0 5px;\n}\n.Pevxi ul.uk-tab li:last-of-type > a {\n  border-radius: 0 5px 5px 0;\n}\nform._1za5D {\n  width: 250px;\n}\n.UDmKj {\n  color: #BD9471;\n}\n.UDmKj:hover,\n.UDmKj:active,\n.UDmKj:focus {\n  color: #88733c;\n}\n._2KvzF {\n  padding-bottom: 20px;\n}\n._2KvzF textarea {\n  border: 1px solid #EFF1F2;\n}\n._2KvzF ._1uUJ1 {\n  color: #49aa0d;\n}\n._2KvzF .mgc7Z {\n  margin-bottom: 40px !important;\n}\n._1GTJK {\n  width: 27px;\n  height: 27px;\n}\n", ""]);

// Exports
exports.locals = {
	"cartHeader": "C8znr",
	"tabs": "Pevxi",
	"formSearch": "_1za5D",
	"btnAddServiceItem": "UDmKj",
	"footer": "_2KvzF",
	"discount": "_1uUJ1",
	"icon": "mgc7Z",
	"spinner": "_1GTJK"
};