import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';
import Modal from 'Components/modal';
import Button from 'Components/button';
import loc from 'Components/languages';
import styles from './ModalQuantityDiscount.less';

class ModalQuantityDiscount extends PureComponent {
	constructor() {
		super();
		this.defaultState = {
			item_quantity: 1,
			manual_price_amount: 0,
			discountType: 0,
			discount_percentage: 0,
			discount_reduction: 0,
			discount_amount: 0,
		};
		this.state = {
			...this.defaultState
		};
		this.modalStyles = {
			salesOrder: {
				content: {
					left: 'calc(50% - 250px)',
					right: 'calc(50% - 250px)',
					maxHeight: '392px'
				},
			},
			serviceMemo: {
				content: {
					left: 'calc(50% - 250px)',
					right: 'calc(50% - 250px)',
					maxHeight: '392px'
				},
			},
			shopOrder: {
				content: {
					left: 'calc(50% - 250px)',
					right: 'calc(50% - 250px)',
					maxHeight: '150px',
				},
			},
		};
		this.onChangeQuantityHandler = this.onChangeQuantityHandler.bind(this);
		this.onChangeManualPriceAmountHandler = this.onChangeManualPriceAmountHandler.bind(this);
		this.onChangeDiscountTypeHandler = this.onChangeDiscountTypeHandler.bind(this);
		this.onChangeDiscountHandler = this.onChangeDiscountHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const transactionItem = this.props.transactionItem;
		const prevTransactionItem = prevProps.transactionItem;
		if (transactionItem && transactionItem !== prevTransactionItem) {
			let newState = {
				...this.defaultState,
				item_quantity: transactionItem.get('item_quantity'),
			};

			if (transactionItem.get('manual_price_amount')) {
				newState.manual_price_amount = transactionItem.get('manual_price_amount');
			} else {
				newState.manual_price_amount = transactionItem.get('unit_price');
			}

			if (transactionItem.get('discount_percentage')) {
				newState.discountType = 0;
				newState.discount_percentage = transactionItem.get('discount_percentage');
			} else if (transactionItem.get('discount_reduction')) {
				newState.discountType = 1;
				newState.discount_reduction = transactionItem.get('discount_reduction');
			} else if (transactionItem.get('discount_amount')) {
				newState.discountType = 2;
				newState.discount_amount = transactionItem.get('discount_amount');
			}
			this.setState(newState);
		}
	}

	onChangeQuantityHandler(e) {
		this.setState({
			item_quantity: parseInt(e.target.value) || 0,
		});
	}

	onChangeManualPriceAmountHandler(e) {
		this.setState({
			manual_price_amount: e.target.value,
		});
	}

	onChangeDiscountTypeHandler(e) {
		this.setState({
			discountType: parseInt(e.target.value),
			discount_percentage: 0,
			discount_reduction: 0,
			discount_amount: 0,
		});
	}

	onChangeDiscountHandler(e) {
		const { discountType } = this.state;
		let field;
		let value = e.target.value;
		let newState = {};
		switch (discountType) {
		case 0:
			field = 'discount_percentage';
			value = Math.min(Math.max(parseInt(value) || 0, 0), 100);
			break;
		case 1:
			field = 'discount_reduction';
			break;
		case 2:
			field = 'discount_amount';
			break;
		}
		newState[field] = value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { onChange, onToggle, transactionItem, type } = this.props;
		const { discountType, item_quantity, manual_price_amount, discount_percentage, discount_reduction, discount_amount } = this.state;
		let data = {
			item_quantity,
			discount_percentage,
			discount_reduction,
		};
		if (type === 'salesOrder' || type === 'serviceMemo') {
			data.manual_price_amount = manual_price_amount === '' ? 0 : manual_price_amount;
		}
		if (discountType == 2) {
			data.discount_reduction = transactionItem.get('retail_amount') - discount_amount;
		}
		onChange(data);
		onToggle();
	}

	onCancelHandler() {
		const { onToggle } = this.props;
		this.setState(this.defaultState, onToggle);
	}

	render() {
		const { isOpen, type, onToggle } = this.props;
		const { item_quantity, manual_price_amount, discountType, discount_percentage, discount_reduction, discount_amount } = this.state;
		let discount;
		switch (discountType) {
		case 0:
			discount = discount_percentage;
			break;
		case 1:
			discount = discount_reduction;
			break;
		case 2:
			discount = discount_amount;
			break;
		}
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyles[type] }
			>
				<form className="uk-form-stacked" onSubmit={ this.onSubmitHandler }>
					<div className="uk-margin">
						<label className={ classnames('uk-form-label', styles.label) }>{ loc.qty }</label>
						<div className="uk-form-controls">
							<input
								className={ classnames('uk-input', styles.inputQty) }
								type="text"
								placeholder={ loc.qty }
								value={ item_quantity }
								onChange={ this.onChangeQuantityHandler }
							/>
						</div>
					</div>

					{
						// type === 'serviceMemo' && (
						(type === 'salesOrder' || type === 'serviceMemo') && (
							<div className="uk-margin">
								<label className={ classnames('uk-form-label', styles.label) }>{ loc.unitPrice }</label>
								<div className="uk-form-controls">
									<input
										className={ classnames('uk-input', styles.inputQty) }
										type="text"
										placeholder={ loc.unitPrice }
										value={ manual_price_amount }
										onChange={ this.onChangeManualPriceAmountHandler }
									/>
								</div>
							</div>
						)
					}

					{
						(type === 'salesOrder' || type === 'serviceMemo') && (
							<div className="uk-margin">
								<label className={ classnames('uk-form-label', styles.label) }>{ loc.discount }</label>

								<div className="uk-form-controls uk-form-controls-text uk-flex uk-grid-small uk-width-1-1 uk-margin-small-top">
									<div className="uk-width-1-2">
										<label className="uk-text-capitalize uk-text-center">
											<input
												className="uk-radio"
												type="radio"
												name="discount"
												value={ 0 }
												checked={ discountType === 0 }
												onChange={ this.onChangeDiscountTypeHandler }
											/> { loc.discount } %
										</label>
									</div>
									<div className="uk-width-1-2">
										<label className="uk-text-capitalize uk-text-center">
											<input
												className="uk-radio"
												type="radio"
												name="discount"
												value={ 1 }
												checked={ discountType === 1 }
												onChange={ this.onChangeDiscountTypeHandler }
											/> { loc.discount } $
										</label>
									</div>
									{/* <div className="uk-width-1-3">
										<label className="uk-text-capitalize uk-text-center">
											<input
												className="uk-radio"
												type="radio"
												name="discount"
												value={ 2 }
												checked={ discountType === 2 }
												onChange={ this.onChangeDiscountTypeHandler }
											/> { loc.amount } $
										</label>
									</div> */}
								</div>
								<div className="uk-form-controls uk-margin-small-top">
									{
										discountType !== 0 && (
											<div className={ styles.unit }>$</div>
										)
									}
									<input
										className={ classnames('uk-input', styles.input) }
										type="text"
										value={ discount }
										onChange={ this.onChangeDiscountHandler }
									/>
									{
										discountType === 0 && (
											<div className={ styles.unit }>%</div>
										)
									}
								</div>
							</div>
						)
					}

					<div className="uk-margin-small-top uk-text-center uk-flex">
						<div className={ classnames('uk-width-1-2', styles.buttonColumnLeft) }>
							<Button
								text={ loc.cancel }
								theme="primary"
								type="button"
								className="uk-width-1-1"
								onClick={ this.onCancelHandler }
							/>
						</div>
						<div className={ classnames('uk-width-1-2', styles.buttonColumnRight) }>
							<Button
								text={ loc.apply }
								theme="primary"
								type="submit"
								className="uk-width-1-1"
								disabled={ item_quantity === 0 }
								onClick={ this.onSubmitHandler }
							/>
						</div>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalQuantityDiscount.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	transactionItem: PropTypes.instanceOf(Map).isRequired,
};

export default ModalQuantityDiscount;