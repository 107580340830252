import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from 'Components/button';
import loc from 'Components/languages';
import InputField from 'Components/input-field';
import ButtonAdditionalOptions from '../components/ButtonAdditionOptions.jsx';
import ModalSalesman from './components/ModalSalesman.jsx';
import ModalMember from './components/ModalMember.jsx';
import ModalPurpose from './components/ModalPurpose.jsx';

class PageSalesOrderSalesman extends PureComponent {
	constructor() {
		super();
		this.state = {
			showModalSalesman: false,
			showModalMember: false,
			showModalPurpose: false,
		};
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onSelectMemberHandler = this.onSelectMemberHandler.bind(this);
		this.onSelectPurposeHandler = this.onSelectPurposeHandler.bind(this);
		this.onToggleModalSalesman1Handler = this.onToggleModalSalesmanHandler.bind(this, 'salesman1');
		this.onToggleModalSalesman2Handler = this.onToggleModalSalesmanHandler.bind(this, 'salesman2');
		this.onToggleModalOptometristHandler = this.onToggleModalSalesmanHandler.bind(this, 'optometrist');
		this.onToggleModalMemberHandler = this.onToggleModalMemberHandler.bind(this);
		this.onToggleModalPurposeHandler = this.onToggleModalPurposeHandler.bind(this);
		this.onCloseModalHandler = this.onToggleModalSalesmanHandler.bind(this, '');
		this.onClickContinueHandler = this.onClickContinueHandler.bind(this);
	}

	onSelectSalesmanHandler(salesman) {
		const { showModalSalesman } = this.state;
		const { onSelectSalesman } = this.props;
		this.setState({
			showModalSalesman: false,
		});
		onSelectSalesman(showModalSalesman, salesman);
	}

	onSelectMemberHandler(member) {
		const { onSelectMember } = this.props;
		this.setState({
			showModalMember: false,
		});
		onSelectMember(member);
	}

	onSelectPurposeHandler(purpose) {
		const { onSelectPurpose } = this.props;
		this.setState({
			showModalPurpose: false,
		});
		onSelectPurpose(purpose);
	}

	onToggleModalSalesmanHandler(salesmanType, e) {
		e.target.blur();
		const { showModalSalesman } = this.state;
		this.setState({
			showModalSalesman: showModalSalesman ? false : salesmanType,
		});
	}

	onToggleModalMemberHandler(e) {
		e.target.blur();
		const { showModalMember } = this.state;
		this.setState({
			showModalMember: !showModalMember,
		});
	}

	onToggleModalPurposeHandler(e) {
		e.target.blur();
		const { showModalPurpose } = this.state;
		this.setState({
			showModalPurpose: !showModalPurpose,
		});
	}

	onClickContinueHandler(e) {
		e.preventDefault();
		const { next, onStepClick } = this.props;
		onStepClick(next);
	}

	render() {
		const { salesOrder, type, isDisabled, onToggleModalSpecialOptions } = this.props;
		const { showModalSalesman, showModalMember, showModalPurpose } = this.state;
		const member = salesOrder.get('member');
		return (
			<Fragment>
				<div
					className="stepContainer uk-height-large uk-position-relative"
					data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
				>
					<div className="uk-child-width-1-2" data-uk-grid>
						<div>
							<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">
								{ loc.salesmanDetails } *
							</h3>
							<div className="uk-margin-medium-bottom uk-first-column">
								<InputField
									label={ loc.mainSalesman }
									footnote={ salesOrder.get('salesman_name1') }
									icon={ faSearch }
									value={ salesOrder.get('salesman_code1') }
									onFocus={ this.onToggleModalSalesman1Handler }
									readOnly
								/>
							</div>
							{/* {
								type !== 'shopOrder' && (
									<Fragment>
										<div className="uk-margin-medium-bottom uk-first-column">
											<InputField
												label={ loc.supportSalesman }
												required={ true }
												footnote={ salesOrder.get('salesman_name2') }
												icon={ faSearch }
												value={ salesOrder.get('salesman_code2') }
												onFocus={ this.onToggleModalSalesman2Handler }
												readOnly
											/>
										</div>
										<div className="uk-margin-large-top">
											<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">{ loc.optometristDetails }</h3>
											<div>
												<InputField
													label={ loc.optometrist }
													required={ true }
													footnote={ salesOrder.get('optometrist_name') }
													icon={ faSearch }
													value={ salesOrder.get('optometrist_code') }
													onFocus={ this.onToggleModalOptometristHandler }
													readOnly
												/>
											</div>
										</div>
									</Fragment>
								)
							} */}

							{
								type === 'salesOrder' && (
									<Fragment>
										<div className="uk-margin-large-top">
											<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">{ loc.brandAmbassador }</h3>
											<div>
												<InputField
													required={ true }
													footnote={ salesOrder.get('salesman_name2') }
													icon={ faSearch }
													value={ salesOrder.get('salesman_code2') }
													onFocus={ this.onToggleModalSalesman2Handler }
													readOnly
												/>
											</div>
										</div>
										<div className="uk-margin-large-top">
											<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">{ loc.purpose } *</h3>
											<div>
												<InputField
													required={ true }
													icon={ faSearch }
													value={ salesOrder.get('customer_anly_description') }
													onFocus={ this.onToggleModalPurposeHandler }
													readOnly
												/>
											</div>
										</div>
									</Fragment>
								)
							}

							<div className="uk-margin-medium uk-text-small uk-text-muted uk-text-bold">
								{ loc.MSGFieldsRequired }
							</div>
						</div>
						<div>
							<h3 className="uk-text-primary uk-text-bold uk-text-uppercase">{ loc.memberDetails }</h3>
							<div className="uk-margin-right">
								<div className="uk-margin-medium-bottom">
									<InputField
										label={ loc.memberNumber }
										icon={ faSearch }
										value={ salesOrder.get('member_code') }
										onFocus={ this.onToggleModalMemberHandler }
										readOnly
									/>
								</div>
								<div className="uk-margin-medium-bottom">
									<h5 className="uk-margin-remove">
										{ loc.memberName }
									</h5>
									<div className="uk-text-muted">
										{ member && member.member_name }
									</div>
								</div>
								<div className="uk-margin-medium-bottom">
									<h5 className="uk-margin-remove">
										{ loc.mobilePhone }
									</h5>
									<div className="uk-text-muted">
										{ member && member.phone }
									</div>
								</div>
								<div className="uk-margin-medium-bottom">
									<h5 className="uk-margin-remove">
										{ loc.memberTier }
									</h5>
									<div className="uk-text-muted">
										{ member && member.member_group }
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uk-position-bottom-right">
						<ButtonAdditionalOptions
							disabled={ isDisabled }
							onClick={ onToggleModalSpecialOptions }
						/>
						<Button
							text={ loc.continue }
							theme="primary"
							arrow={ true }
							disabled={ isDisabled }
							onClick={ this.onClickContinueHandler }
						/>
					</div>
				</div>

				<ModalSalesman
					isOpen={ !!showModalSalesman }
					onToggle={ this.onCloseModalHandler }
					onSelect={ this.onSelectSalesmanHandler }
					title={ showModalSalesman === 'salesman2' ? loc.brandAmbassadorSearch : loc.salesmanSearch }
					salesmanType={ showModalSalesman || '' }
				/>

				<ModalMember
					isOpen={ !!showModalMember }
					type={ type }
					onToggle={ this.onToggleModalMemberHandler }
					onSelect={ this.onSelectMemberHandler }
				/>

				<ModalPurpose
					isOpen={ !!showModalPurpose }
					onToggle={ this.onToggleModalPurposeHandler }
					onSelect={ this.onSelectPurposeHandler }
				/>
			</Fragment>
		);
	}
}

PageSalesOrderSalesman.propTypes = {
	salesOrder: PropTypes.instanceOf(Map).isRequired,
	type: PropTypes.string.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	next: PropTypes.string.isRequired,
	onStepClick: PropTypes.func.isRequired,
	onToggleModalSpecialOptions: PropTypes.func.isRequired,
	onSelectSalesman: PropTypes.func.isRequired,
	onSelectMember: PropTypes.func.isRequired,
	onSelectPurpose: PropTypes.func.isRequired,
};

export default PageSalesOrderSalesman;