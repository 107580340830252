exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3Qn4E {\n  margin-top: 0 !important;\n  border-top-color: #A0A0A0 !important;\n}\n._3Qn4E {\n  padding-bottom: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n  cursor: pointer;\n}\n._3Qn4E ._1Qv9V {\n  display: inline-block;\n  vertical-align: middle;\n  width: calc(100% - 30px);\n}\n._3Qn4E ._1Qv9V ._3f5Nz {\n  color: #99785C;\n  font-weight: bold;\n  font-size: 18px;\n}\n._3Qn4E ._1Qv9V ._2Am4Z {\n  color: #A0A0A0;\n  font-size: 15px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_3Qn4E",
	"details": "_1Qv9V",
	"title": "_3f5Nz",
	"desc": "_2Am4Z"
};